// extracted by mini-css-extract-plugin
export var amountButtonContainer = "subscriptions-module--amount-button-container--529fc";
export var circleGreen = "subscriptions-module--circle-green--c1f83";
export var circleRed = "subscriptions-module--circle-red--99ed4";
export var editModalForm = "subscriptions-module--editModalForm--86fe7";
export var formLabels = "subscriptions-module--formLabels--c2aa2";
export var readOnlyField = "subscriptions-module--readOnlyField--e28b9";
export var recoverItemsToggle = "subscriptions-module--recover-items-toggle--a46c7";
export var toggle = "subscriptions-module--toggle--f0249";
export var toggleField = "subscriptions-module--toggle-field--8b0b7";
export var toggleLabel = "subscriptions-module--toggle-label--d1989";
export var toggleWarningText = "subscriptions-module--toggle-warning-text--9a0e6";